var transit = require('transit-js');
var Immutable = require('immutable');

function createReader(handlers) {
  return transit.reader('json', {
    mapBuilder: {
      init: function() {
        return {};
      },
      add: function(m, k, v) {
        m[k] = v;
        return m;
      },
      finalize: function(m) {
        return m;
      }
    },
    handlers: handlers
  });
}

function createReaderHandlers(extras, recordMap, missingRecordHandler) {
  var handlers = {
    iM: function(v) {
      var m = Immutable.Map().asMutable();
      for (var i = 0; i < v.length; i += 2) {
        m = m.set(v[i], v[i + 1]);
      }
      return m.asImmutable();
    },
    iOM: function(v) {
      var m = Immutable.OrderedMap().asMutable();
      for (var i = 0; i < v.length; i += 2) {
        m = m.set(v[i], v[i + 1]);
      }
      return m.asImmutable();
    },
    iL: function(v) {
      return Immutable.List(v);
    },
    iS: function(v) {
      return Immutable.Set(v);
    },
    iStk: function(v) {
      return Immutable.Stack(v);
    },
    iOS: function(v) {
      return Immutable.OrderedSet(v);
    },
    iR: function(v) {
      var RecordType = recordMap[v.n];
      if (!RecordType) {
        return missingRecordHandler(v.n, v.v);
      }

      return new RecordType(v.v);
    }
  };
  extras.forEach(function(extra) {
    handlers[extra.tag] = extra.read;
  });
  return handlers;
}

function createWriter(handlers) {
  return transit.writer('json', {
    handlers: handlers
  });
}

function createWriterHandlers(extras, recordMap, predicate) {
  function mapSerializer(m) {
    var i = 0;
    if (predicate) {
      m = m.filter(predicate);
    }
    var a = new Array(2 * m.size);
    m.forEach(function(v, k) {
      a[i++] = k;
      a[i++] = v;
    });
    return a;
  }

  var handlers = transit.map([
    Immutable.OrderedMap, transit.makeWriteHandler({
      tag: function() {
        return 'iOM';
      },
      rep: mapSerializer
    }),
    Immutable.Map, transit.makeWriteHandler({
      tag: function() {
        return 'iM';
      },
      rep: mapSerializer
    }),
    Immutable.List, transit.makeWriteHandler({
      tag: function() {
        return "iL";
      },
      rep: function(v) {
        if (predicate) {
          v = v.filter(predicate);
        }
        return v.toArray();
      }
    }),
    Immutable.Stack, transit.makeWriteHandler({
      tag: function() {
        return "iStk";
      },
      rep: function(v) {
        if (predicate) {
          v = v.filter(predicate);
        }
        return v.toArray();
      }
    }),
    Immutable.OrderedSet, transit.makeWriteHandler({
      tag: function() {
        return "iOS";
      },
      rep: function(v) {
        if (predicate) {
          v = v.filter(predicate);
        }
        return v.toArray();
      }
    }),
    Immutable.Set, transit.makeWriteHandler({
      tag: function() {
        return "iS";
      },
      rep: function(v) {
        if (predicate) {
          v = v.filter(predicate);
        }
        return v.toArray();
      }
    }),
    Function, transit.makeWriteHandler({
      tag: function() {
        return '_';
      },
      rep: function() {
        return null;
      }
    }),
    "default", transit.makeWriteHandler({
      tag: function() {
        return 'iM';
      },
      rep: function(m) {
        if ((Immutable.isImmutable && Immutable.isImmutable(m)) || ('toMap' in m)) {
          return mapSerializer(Immutable.Map(m));
        }
        var e = "Error serializing unrecognized object " + m.toString();
        throw new Error(e);
      }
    })
  ]);

  Object.keys(recordMap).forEach(function(name) {
    handlers.set(recordMap[name], makeRecordHandler(name, predicate));
  });

  extras.forEach(function(extra) {
    handlers.set(extra.class, transit.makeWriteHandler({
      tag: function() { return extra.tag; },
      rep: extra.write
    }));
  });

  return handlers;
}

function validateExtras(extras) {
  if (!Array.isArray(extras)) {
    invalidExtras(extras, "Expected array of handlers, got %j");
  }
  extras.forEach(function(extra) {
    if (typeof extra.tag !== "string") {
      invalidExtras(extra,
        "Expected %j to have property 'tag' which is a string");
    }
    if (typeof extra.class !== "function") {
      invalidExtras(extra,
        "Expected %j to have property 'class' which is a constructor function");
    }
    if (typeof extra.write !== "function") {
      invalidExtras(extra,
        "Expected %j to have property 'write' which is a function");
    }
    if (typeof extra.read !== "function") {
      invalidExtras(extra,
        "Expected %j to have property 'write' which is a function");
    }
  });
}
function invalidExtras(data, msg) {
  var json = JSON.stringify(data);
  throw new Error(msg.replace("%j", json));
}

function recordName(record) {
  /* eslint no-underscore-dangle: 0 */
  /* istanbul ignore next */
  return record._name || record.constructor.name || 'Record';
}

function makeRecordHandler(name) {
  return transit.makeWriteHandler({
    tag: function() {
      return 'iR';
    },
    rep: function(m) {
      return {
        n: name,
        v: m.toObject()
      };
    }
  });
}

function buildRecordMap(recordClasses) {
  var recordMap = {};

  recordClasses.forEach(function(RecordType) {
    var rec = new RecordType();
    var recName = recordName(rec);

    if (!recName || recName === 'Record') {
      throw new Error('Cannot (de)serialize Record() without a name');
    }

    if (recordMap[recName]) {
      throw new Error('There\'s already a constructor for a Record named ' +
                      recName);
    }
    recordMap[recName] = RecordType;
  });

  return recordMap;
}

function defaultMissingRecordHandler(recName) {
  var msg = 'Tried to deserialize Record type named `' + recName + '`, ' +
            'but no type with that name was passed to withRecords()';
  throw new Error(msg);
}

function createInstanceFromHandlers(handlers) {
  var reader = createReader(handlers.read);
  var writer = createWriter(handlers.write);

  return {
    toJSON: function toJSON(data) {
      return writer.write(data);
    },
    fromJSON: function fromJSON(json) {
      return reader.read(json);
    },
    withExtraHandlers: function(extra) {
      return createInstanceFromHandlers(handlers.withExtraHandlers(extra));
    },
    withFilter: function(predicate) {
      return createInstanceFromHandlers(handlers.withFilter(predicate));
    },
    withRecords: function(recordClasses, missingRecordHandler) {
      return createInstanceFromHandlers(
        handlers.withRecords(recordClasses, missingRecordHandler)
      );
    }
  };
}

function createHandlers(options) {
  var records = options.records || {};
  var filter = options.filter || false;
  var missingRecordFn = options.missingRecordHandler
                          || defaultMissingRecordHandler;
  var extras = options.extras || [];

  return {
    read: createReaderHandlers(extras, records, missingRecordFn),
    write: createWriterHandlers(extras, records, filter),
    withExtraHandlers: function(moreExtras) {
      validateExtras(moreExtras);

      return createHandlers({
        extras: extras.concat(moreExtras),
        records: records,
        filter: filter,
        missingRecordHandler: missingRecordFn
      });
    },
    withFilter: function(newFilter) {
      return createHandlers({
        extras: extras,
        records: records,
        filter: newFilter,
        missingRecordHandler: missingRecordFn
      });
    },
    withRecords: function(recordClasses, missingRecordHandler) {
      var recordMap = buildRecordMap(recordClasses);
      return createHandlers({
        extras: extras,
        records: recordMap,
        filter: filter,
        missingRecordHandler: missingRecordHandler
      });
    }
  };
}

module.exports = createInstanceFromHandlers(createHandlers({}));
module.exports.handlers = createHandlers({});
